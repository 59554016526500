<template>
  <div class="docinfo">
    <nav-menu></nav-menu>
    <div class="contact-us">
      <markdown-it-vue
        class="md-body"
        :content="content" />
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import SiteFooter from '@/components/Footer.vue';
import navMenu from '@/components/NavMenu';
import { getDocsInfo } from '@/api/docs';
import MarkdownItVue from 'markdown-it-vue';

export default {
  components: {
    MarkdownItVue,
    SiteFooter,
    navMenu,
  },
  data() {
    return {
      content: '',
      title: '',
    };
  },
  methods: {},
  created() {},
  mounted() {
    getDocsInfo({
      id: this.$route.query.id,
    }).then((res) => {
      this.content = res.data;
    });
    document.title = `${this.$route.query.name} | BrickSDK`;
  },
};
</script>

<style>
.md-body img {
  width: auto;
}
</style>
<style lang="scss" scoped>
.docinfo {
  .md-body {
    width: 75%;
    margin: 20px auto 0 auto;
    padding: 30px;
  }
}
</style>
